import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { dbFights, firestore } from "../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { collection, getDoc, getDocs, updateDoc, doc, query, where, onSnapshot, limit, orderBy } from "firebase/firestore";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Alert from '@mui/material/Alert';
import { styled as styledMui } from '@mui/material/styles';
import Loading from "./Loading";
import { Spin } from "antd";
import Modal from '@mui/material/Modal';
import ErrorIcon from '@mui/icons-material/Error';
import ChatFigth from "./fight/ChatFight";
import { HistoryOutlined } from "@ant-design/icons";
import { Modal as ModalAntd, Timeline } from 'antd';
import styled from "styled-components";
import { format } from 'date-fns';
import './FightStatus.css'
import { useUserAuth } from "../context/UserAuthContext";
import HistoryDataService from "../Services/history.services";

const IOSSwitch = styledMui((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1D1D1B' : '#1D1D1B',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const styleSuccess = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  minHeight: 250,
  maxHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const HistoryText = styled.p`
  color: #fff;
  font-size: 14px;
  padding: 0;
  margin: 0;
`;


const FightStatus = () => {
  const navigate = useNavigate();
  const paramsIdFight = useParams();

  //Data variables
  const [fightName, setFightName] = useState("");
  const [status, setStatus] = useState(true);
  const [betStatus, setBetStatus] = useState(false);
  const [live, setLive] = useState(false);
  const [winner, setWinner] = useState("");

  //Switches variables
  const [livecheck, setLiveCheck] = useState(false);
  const [betCheck, setBetChecked] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const [liveLabel, setLiveLabel] = useState("Iniciar");
  const [betLabel, setBetLabel] = useState("Iniciar");
  const [statusLabel, setStatusLabel] = useState("Iniciar");

  //Bets
  const [bets, setBets] = useState([]);

  const [revenue, setRevenue] = useState(0);
  const [totalBetAmount, setTotalBetAmount] = useState(0);

  //Others
  const urlVideoLocal = "https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/fe7e9cb2-6a28-f0c0-d3bd-80ba025d3161";
  const [uiEmparejamiento, setUiEmparejamiento] = useState(false);
  const [ended, setEnded] = useState(false); //This const check if bet rewards where retrieved

  const [time, setTime] = useState(new Date());
  const [isNextFight, setIsnextFight] = useState(true);

  const [chatAdmin, setChatAdmin] = useState(true);
  const [msgChat, setMsgChat] = useState("Detener chat");

  const [loading, setLoading] = useState(false);

  const [openModalError, setOpenModalError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [history, setHistory] = useState([]);
  const { user } = useUserAuth();

  //===============================================================

  const createHistory = async (description, initialStatus, newStatus) => {
    const newDate = new Date();

    const docData = {
      uidUser: user.uid,
      emailUser: user.email,
      uidFight: paramsIdFight.idFight,
      date: newDate,
      initialStatus: initialStatus,
      newStatus: newStatus,
      description: description
    };
    await HistoryDataService.addHistory(docData);
  }

  const handleWinnerButton = async (winnerString) => {
    //Update firestore Fights
    const fightDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    await updateDoc(fightDoc, {
      winner: winnerString
    });
    if (paramsIdFight.idFight !== undefined && paramsIdFight.idFight !== "") {
      GetData();
    }
    const newDate = new Date();

    const docData = {
      uidUser: user.uid,
      emailUser: user.email,
      uidFight: paramsIdFight.idFight,
      date: newDate,
      initialWinner: winner,
      newWinner: winnerString,
      description: "Selección de ganador"
    };
    await HistoryDataService.addHistory(docData);
  };

  const handleChangeLive = async (event) => {
    setLiveCheck(event.target.checked);
    setLive(event.target.checked);
    if (!live) {
      setLiveLabel("Detener");
    }
    else { setLiveLabel("Iniciar"); }

    //Update firestore Fights
    const fightDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    const docSnap = await getDoc(fightDoc);
    const liveDoc = (docSnap.data().live);
    await updateDoc(fightDoc, {
      live: !liveDoc
    });
    createHistory("Cambio de estatus de transmisión", live, event.target.checked);
  };

  const handleChangeBetStatus = async (event) => {
    setBetChecked(event.target.checked);
    setBetStatus(event.target.checked);
    if (!betStatus) {
      setBetLabel("Detener");
    }
    else { setBetLabel("Iniciar"); }

    //Update firestore Fights
    const fightDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    const docSnap = await getDoc(fightDoc);
    const betDoc = (docSnap.data().betStatus);
    await updateDoc(fightDoc, {
      betStatus: !betDoc
    });
    createHistory("Cambio de estatus de apuestas", betCheck, event.target.checked);
  };

  const handleChangeStatusFight = async (event) => {
    setStatusCheck(event.target.checked);
    setStatus(event.target.checked);
    if (!status) {
      setStatusLabel("Detener");
    }
    else { setStatusLabel("Iniciar"); }

    //Update firestore Fights
    const fightDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    const docSnap = await getDoc(fightDoc);
    const statusDoc = (docSnap.data().status);
    await updateDoc(fightDoc, {
      status: !statusDoc
    });
    createHistory("Cambio de estatus de pelea", status, event.target.checked);
  };

  const getBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      let emparejada = true;
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "por aprobar") {
          emparejada = false;
        }
        bet.push({ ...doc.data(), id: doc.id });
      });
      setBets(bet);
      setUiEmparejamiento(emparejada);
    });

    return () => unsubscribe();
  };

  //Emparejamiento con prioridad a las apuestas más altas
  const handleEmparejamiento = async () => {
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/match-bets`, {
        method: "POST",
        body: JSON.stringify({ id: paramsIdFight.idFight }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setLoading(false);
      if (response.status !== 200) {
        console.error("Res: ", response)
        setMessageError(data.message);
        setOpenModalError(true);
        return;
      }
      setUiEmparejamiento(true);
      const newDate = new Date();
      const docData = {
        uidUser: user.uid,
        emailUser: user.email,
        uidFight: paramsIdFight.idFight,
        date: newDate,
        description: "Emparejamiento de apuestas"
      };
      await HistoryDataService.addHistory(docData);
    } catch (error) {

      setLoading(false);
      console.error("Error: ", error);
      setMessageError(error?.message);
      setOpenModalError(true);
    }
  };

  const getGalloWinRevenue = async () => {
    const betsCollectionRef = collection(firestore, "Bets");

    //Get all bets from specific fight and open: false 
    const betsQuery = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight), where("open", "==", false));
    const QuerySnapshot = await getDocs(betsQuery);
    const items = [];
    QuerySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    let calculateRevenue = (items.reduce((a, v) => a = a + v.bet, 0))
    setTotalBetAmount(calculateRevenue);
    setRevenue(calculateRevenue * 0.05);
  }

  const getGalloWinRevenueEmpate = async () => {
    const betsCollectionRef = collection(firestore, "Bets");

    //Get all bets from specific fight and open: false 
    const betsQuery = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight), where("open", "==", false));
    const QuerySnapshot = await getDocs(betsQuery);
    const items = [];
    QuerySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    let calculateRevenue = (items.reduce((a, v) => a = a + v.bet, 0))
    setTotalBetAmount(calculateRevenue);
    setRevenue(0);
  }

  const repartirGanancias = async () => {
    setLoading(true);
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/pay-bets`, {
        method: "POST",
        body: JSON.stringify({ figthId: paramsIdFight.idFight }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setLoading(false);
      if (response.status !== 200) {
        setMessageError(data.message);
        setOpenModalError(true);
        return;
      }
      if (winner === "Verde" || winner === "Rojo") {
        getGalloWinRevenue();
      }
      else {
        getGalloWinRevenueEmpate();
      }
      setEnded(true);
      const newDate = new Date();
      const docData = {
        uidUser: user.uid,
        emailUser: user.email,
        uidFight: paramsIdFight.idFight,
        date: newDate,
        description: "Repartición de ganancias"
      };
      await HistoryDataService.addHistory(docData);
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error)
      setMessageError(error?.message);
      setOpenModalError(true);
    }
  }

  const returnEarnings = async () => {
    setLoading(true);
    const auth = getAuth();
    const { currentUser } = auth;
    const token = await getIdToken(currentUser);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/return-earnings`, {
        method: "POST",
        body: JSON.stringify({ figthId: paramsIdFight.idFight }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      setLoading(false);
      if (response.status !== 200) {
        setMessageError(data.message);
        setOpenModalError(true);
        return;
      }
      setEnded(false);
      const newDate = new Date()
      const docData = {
        uidUser: user.uid,
        emailUser: user.email,
        uidFight: paramsIdFight.idFight,
        date: newDate,
        description: "Regresar ganancias"
      };
      await HistoryDataService.addHistory(docData);
    } catch (error) {
      setLoading(false);
      console.error("Error: ", error)
      setMessageError(error?.message);
      setOpenModalError(true);
    }
  }

  const GetData = async () => {
    const fightDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    const docSnap = await getDoc(fightDoc);

    //Set data
    setFightName(docSnap.data().fightName);
    setStatus(docSnap.data().status);
    setBetStatus(docSnap.data().betStatus);
    setLive(docSnap.data().live);
    setWinner(docSnap.data().winner);
    //Set state to switches
    setLiveCheck(docSnap.data().live);
    setBetChecked(docSnap.data().betStatus);
    setStatusCheck(docSnap.data().status);
    setEnded(docSnap.data().ended);

    if (docSnap.data().live) {
      setLiveLabel("Detener");
    }
    if (docSnap.data().betStatus) {
      setBetLabel("Detener");
    }
    if (docSnap.data().status) {
      setStatusLabel("Detener");
    }

    if (docSnap.data().ended && (docSnap.data().winner === "Verde" || docSnap.data().winner === "Rojo")) {
      getGalloWinRevenue();
    }
    if (docSnap.data().ended && docSnap.data().winner === "Empate") {
      getGalloWinRevenueEmpate();
    }

    let chatState = false;

    if (docSnap.data().chat !== null) {
      setChatAdmin(docSnap.data().chat);
      chatState = docSnap.data().chat;
    } else {
      setChatAdmin(true);
      chatState = true;
    }

    if (chatState) {
      setMsgChat("Detener chat");
    } else setMsgChat("Activar chat");

    let timeTemp;
    timeTemp = docSnap.data().createDate;
    setTime(timeTemp);
  }

  const handleNextFight = async () => {
    const ref = collection(firestore, "Fights");
    const q = query(ref, where("createDate", ">", time), limit(1));

    const item = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push({ ...doc.data(), id: doc.id });
    });
    if (item.length === 0) {
      setIsnextFight(false);
      return;
    }
    let linkTo = "";
    item.forEach((data, index) => {
      linkTo = data.id;
    });

    if (isNextFight) {
      navigate("/fightview-admin/" + linkTo);
      window.location.reload(false);
    }
  }

  const handleChatAdmin = async () => {
    let chatState = true;
    if (chatAdmin) {
      setChatAdmin(false);
      chatState = false;
      setMsgChat("Activar chat");
    } else {
      setChatAdmin(true);
      chatState = true;
      setMsgChat("Detener chat")
    }

    const chatDoc = doc(dbFights, "Fights", paramsIdFight.idFight);
    await updateDoc(chatDoc, {
      chat: chatState
    });
  }

  const getHistory = async () => {
    const betsCollectionRef = collection(firestore, "History");
    const q = query(
      betsCollectionRef,
      where("uidFight", "==", paramsIdFight.idFight),
      orderBy("date", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const history = [];
      querySnapshot.forEach((doc) => {
        history.push({ ...doc.data(), id: doc.id });
      });
      setHistory(history);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    if (paramsIdFight.idFight !== undefined && paramsIdFight.idFight !== "") {
      GetData();
      getBets();
      getHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin
      style={{
        position: 'fixed', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      size="large"
      spinning={loading}
    >
      <div style={{ backgroundColor: '#141414', minHeight: '100hv', paddingBottom: '10px' }}>
        <Container component="main" maxWidth="md">
          <Container maxWidth="md" sx={{ mt: 4 }}>
            <div className="container-title">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ArrowBackIosNewIcon
                  fontSize="inherit"
                  color="success"
                  style={{ fontSize: "22px", color: '#fff', marginRight: '5px' }}
                  onClick={(e) => navigate("/lista-peleas")}
                />
                <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#fff' }}>
                  {fightName}
                </Typography>
              </div>
              <div>
                <HistoryOutlined style={{ fontSize: "30px", color: '#fff' }} onClick={() => setOpenModal(true)} />
              </div>
            </div>
            <div style={{ position: "relative", borderRadius: 22, paddingBottom: "56.25%", overflow: "hidden", height: "0", maxWidth: "100%" }}>
              {live ?
                <iframe id="fe7e9cb2-6a28-f0c0-d3bd-80ba025d3161"
                  src={urlVideoLocal}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  scrolling="no"
                  allow="autoplay;encrypted-media"
                  allowfullscreen
                  webkitallowfullscreen
                  mozallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  style={{ position: "absolute", top: "0", left: "0" }}
                  title='Live'>
                </iframe> :
                <Box sx={{
                  display: 'flex',
                  backgroundColor: "black",
                  height: "500px"
                }}></Box>
              }
            </div>
            <TableContainer component={Paper} sx={{ mt: 4, width: "100%" }} style={{ borderRadius: 8, backgroundColor: '#141414', border: '1px solid #1C1C1C' }}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" align="center" >
                      <Grid container spacing={0} direction="column" alignItems="center">
                        <Grid item xs={4.5} sx={{ mt: 1, }}>
                          <Typography component="h1" variant="h5" align="center" style={{ fontSize: "16px", color: '#fff', fontWeight: "bold" }}>
                            Transmisión
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ mt: 1.5 }}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={livecheck}
                                onChange={handleChangeLive}
                              />
                            }
                            label={liveLabel}
                            sx={{ color: '#fff' }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container spacing={0} direction="column" alignItems="flex-start">
                        <Grid item xs={12} sx={{ mt: 1, }}>
                          <Typography component="h1" variant="h5" align="center" style={{ fontSize: "16px", color: '#fff', fontWeight: "bold" }}>
                            Apuestas
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 1.5, }}>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }}
                              checked={betCheck}
                              onChange={handleChangeBetStatus} />}
                            label={betLabel}
                            sx={{ color: '#fff' }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={2} direction="column" alignItems="flex-start">
                        <Grid item xs={12} sx={{ mt: 1, }}>
                          <Typography component="h1" variant="h5" align="center" style={{ fontSize: "16px", color: '#fff', fontWeight: "bold" }}>
                            Ganador de la pelea
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0.1} direction="row" alignItems="flex-start">
                        <Grid item xs={12} md={4} lg={4} sx={{ mt: 1.5 }}>
                          {winner === "Verde" &&
                            <Button variant="contained" color="success" size="small"
                              style={{
                                borderRadius: '6px',
                                padding: "8px 18px",
                                fontSize: "12px"
                              }}>
                              Verde
                            </Button>}
                          {winner !== "Verde" &&
                            <Button variant="outlined" color="success" size="small"
                              onClick={(e) => handleWinnerButton("Verde")}
                              style={{
                                borderRadius: '6px',
                                padding: "8px 18px",
                                fontSize: "12px"
                              }}>
                              Verde
                            </Button>}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sx={{ mt: 1.5 }}>
                          {winner === "Rojo" &&
                            <Button variant="contained" color="error" size="small"
                              style={{
                                borderRadius: '6px',
                                padding: "8px 20px",
                                fontSize: "12px"
                              }}>
                              Rojo
                            </Button>}
                          {winner !== "Rojo" &&
                            <Button variant="outlined" color="error" size="small"
                              onClick={(e) => handleWinnerButton("Rojo")}
                              style={{
                                borderRadius: '6px',
                                padding: "8px 20px",
                                fontSize: "12px"
                              }}>
                              Rojo
                            </Button>}
                        </Grid>
                        <Grid item xs={12} md={4} lg={4} sx={{ mt: 1.5 }}>
                          {winner === "Empate" &&
                            <Button variant="contained" color="primary" size="small"
                              style={{
                                borderRadius: '6px',
                                padding: "8px 16px",
                                fontSize: "12px"
                              }}>
                              Empate
                            </Button>}
                          {winner !== "Empate" &&
                            <Button variant="outlined" color="primary" size="small"
                              onClick={(e) => handleWinnerButton("Empate")}
                              style={{
                                borderRadius: '6px',
                                padding: "8px 16px",
                                fontSize: "12px"
                              }}>
                              Empate
                            </Button>}
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container spacing={0} direction="column" alignItems="flex-start">
                        <Grid item xs={4.5} sx={{ mt: 1, }}>
                          <Typography component="h1" variant="h5" align="left" style={{ fontSize: "16px", color: '#fff', fontWeight: "bold" }}>
                            Pelea
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ mt: 1.5, }}>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }}
                              checked={statusCheck}
                              onChange={handleChangeStatusFight} />}
                            label={statusLabel}
                            sx={{ color: '#fff' }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div className="container-options">
              <Grid item xs={2} sx={{ mb: 1, mt: 1, mr: 2 }}>
                <Button variant="outlined" color="inherit" size="medium"
                  onClick={(e) => handleChatAdmin(e)}
                  style={{
                    borderRadius: '6px',
                    padding: "8px 20px",
                    fontSize: "12px",
                    color: "crimson"
                  }}>
                  {msgChat}
                </Button>
              </Grid>
              {isNextFight &&
                <Grid item xs={3} sx={{ mb: 1, mt: 1, mr: 1 }}>
                  <Button variant="outlined" color="inherit" size="medium"
                    onClick={(e) => handleNextFight(e)}
                    style={{
                      borderRadius: '6px',
                      padding: "8px 20px",
                      fontSize: "12px",
                      color: "#fff"
                    }}>
                    Siguiente pelea
                  </Button>
                </Grid>
              }
              {!isNextFight ?
                <Grid item xs={12} sx={{ mb: 1, mt: 1, mr: 3 }}>
                  <Alert severity="info">No hay más peleas.</Alert>
                </Grid> :
                <></>
              }
            </div>

            <div style={{ marginTop: "1rem", height: '300px' }}>
              <ChatFigth active={chatAdmin} />
            </div>
          </Container>
          <Box sx={{ mt: 5, mb: 5 }}>
            <hr></hr>
          </Box>
          <TableContainer sx={{ mt: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#1A1A1A", color: "white" }}>Color</TableCell>
                  <TableCell style={{ backgroundColor: "#1A1A1A", color: "white" }} align="center">Apuesta</TableCell>
                  <TableCell style={{ backgroundColor: "#1A1A1A", color: "white" }} align="center">Estatus</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bets.map((doc, index) => (
                  <TableRow
                    key={doc.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {doc.color === "Rojo" &&
                      <TableCell component="th" scope="row" style={{ fontSize: "14px", color: '#D32F2F', backgroundColor: "#1A1A1A", }}>
                        {doc.color}
                      </TableCell>
                    }
                    {doc.color === "Verde" &&
                      <TableCell component="th" scope="row" style={{ fontSize: "14px", color: '#2E7D32', backgroundColor: "#1A1A1A", }}>
                        {doc.color}
                      </TableCell>
                    }
                    <TableCell align="center" style={{ backgroundColor: "#1A1A1A", color: "white" }}>{doc.bet}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: "#1A1A1A", color: "white" }}>{doc.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!betCheck ?
            <>
              {!uiEmparejamiento ?
                <>
                  {!loading ?
                    <Button variant="contained" color="primary" size="medium"
                      onClick={(e) => handleEmparejamiento(e)}
                      style={{
                        borderRadius: 8,
                        padding: "8px 20px",
                        fontSize: "12px",
                        backgroundColor: "#E81A1A",
                        color: "#fff"
                      }} sx={{ mt: 2 }}>
                      Emparejar apuestas
                    </Button> :
                    <Loading />
                  }
                </>
                :
                <Button variant="contained" color="primary" size="medium" disabled
                  style={{
                    borderRadius: 8,
                    padding: "8px 20px",
                    fontSize: "12px",
                    backgroundColor: "#E67D7D",
                    color: "#fff"
                  }}
                  sx={{ mt: 2 }}>
                  Apuesta emparejada
                </Button>}

              {uiEmparejamiento ?
                <>
                  {winner !== "" &&
                    <>
                      {!ended ?
                        <>
                          <Button variant="contained" color="warning" size="medium"
                            sx={{ ml: 2, mt: 2 }}
                            onClick={(e) => repartirGanancias()}
                            style={{
                              borderRadius: 8,
                              padding: "8px 20px",
                              fontSize: "12px",
                              backgroundColor: "#1D1D1B",
                              color: "#EA9F26"
                            }}>
                            Repartir ganancias
                          </Button>
                        </>
                        :
                        <>
                          <Button variant="contained" color="warning" size="medium" disabled
                            sx={{ ml: 2, mt: 2 }}
                            style={{
                              borderRadius: 8,
                              padding: "8px 20px",
                              fontSize: "12px",
                              backgroundColor: "#8B8B8B",
                              color: "#fff"
                            }}>
                            Ganancias repartidas
                          </Button>
                          <Button variant="contained" color="warning" size="medium"
                            sx={{ ml: 2, mt: 2 }}
                            onClick={(e) => returnEarnings()}
                            style={{
                              borderRadius: 8,
                              padding: "8px 20px",
                              fontSize: "12px",
                              backgroundColor: "#1D1D1B",
                              color: "#EA9F26"
                            }}>
                            Regresar ganancias repartidas
                          </Button>
                          <Grid container spacing={2} direction="row" alignItems="flex-start" sx={{ mt: 0 }}>
                            <Grid item xs={4.3} sx={{ mt: 1, }}>
                              <Typography component="h1" variant="h5" align="right" style={{ fontSize: "14px", color: '#fff', fontWeight: "bold" }}>
                                Total apostado: {totalBetAmount}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ mt: 1, }}>
                              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "14px", color: '#fff', fontWeight: "bold" }}>
                                Ganancia: {revenue}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      }
                    </>}
                </> : <></>}
            </> : <></>}
        </Container>
      </div>

      {/* Modal error*/}
      <Modal
        open={openModalError}
        onClose={() => setOpenModalError(false)}
        aria-labelledby="modal-modaFl-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSuccess}>
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                {messageError}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
              <ErrorIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "red" }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
              <Button variant="contained" size="small" sx={{ ml: 1 }}
                onClick={() => setOpenModalError(false)}
                style={{
                  borderRadius: 35,
                  padding: "12px 50px",
                  fontSize: "12px",
                  width: "100px",
                  backgroundColor: "#EA9F26",
                  color: "white"
                }}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal History */}
      <ModalAntd
        open={openModal}
        // onOk={onEditAdd}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <h2 style={{ color: '#fff' }}>Historico</h2>
        <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
          <Timeline>
            {history.map((doc, index) => (
              <Timeline.Item key={doc.id} style={{ color: '#fff' }}>
                {doc.description ? <HistoryText>{doc.description} - {format(doc.date.toDate(), 'dd/MM/yyyy - HH:mm')}</HistoryText> : <HistoryText>{doc.descripcion}</HistoryText>}
                {doc.initialWinner ? <HistoryText>Anterior: {doc.initialWinner}</HistoryText> : null}
                {doc.newWinner ? <HistoryText>Nuevo: {doc.newWinner}</HistoryText> : null}
                {doc.hasOwnProperty('email') && <HistoryText>Email: {doc.email}</HistoryText>}
                {doc.hasOwnProperty('initialStatus') && <HistoryText>Anterior: {doc.initialStatus ? 'Activo' : 'Inactivo'}</HistoryText>}
                {doc.hasOwnProperty('newStatus') && <HistoryText>Nuevo: {doc.newStatus ? 'Activo' : 'Inactivo'}</HistoryText>}
                {doc.hasOwnProperty('initialValue') && <HistoryText>Saldo: {doc.initialValue}</HistoryText>}
                {doc.hasOwnProperty('newBalance') && <HistoryText>Nuevo Saldo: {doc.newBalance}</HistoryText>}
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </ModalAntd>
    </Spin>
  )
}

export default FightStatus
