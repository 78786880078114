import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from "../context/UserAuthContext";
import { Divider, List, ListItem, ListItemIcon, ListItemText, Drawer, } from "@material-ui/core";
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from '../firebase';
import Grid from '@mui/material/Grid';
import { viewsUserType } from '../utils/constants';
import LogoCompleto from "../Images/xtremo-logo 1.png";
import iconWallet from "../Images/walletIcon.svg";
import LogoPequeño from "../Images/xtremo-logo 1.png";
import LogoMenu from "../Images/HiMenu.png";
import PersonIcon from '@mui/icons-material/Person';
import './Navbar.css'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  menuSliderContainer: {
    width: 230,
    background: "rgb(20, 20, 20)",
    height: "100vh"
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: theme.spacing(13),
    height: theme.spacing(13)
  },
  listItem: {
    marginTop: "5px",
    color: "rgb(56, 161, 105)"
  },
  listText: {
    color: "#fff",
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.8px",
  },
  divider: {
    borderTop: "3px solid white"
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  menuSliderContainerAdmin: {
    width: 230,
    background: "rgb(20, 20, 20)",
    height: "100vh"
  },
  drawerPaper: {
    width: 180,
  },
}));


export default function ButtonAppBar() {
  const classes = useStyles();
  const { user, logOut, getUserData, showNav } = useUserAuth();
  const navigate = useNavigate();
  const [showNavbarUser, setNavbarUser] = useState(false);
  const [balance, setBalance] = useState(0);

  const [userViews, setUserViews] = useState([]);
  const [navbarIsActive, setNavbarIsActive] = useState(false);
  const { activeCategory, changeCategory } = useUserAuth();
  const currentCategory = activeCategory === null ? 1 : activeCategory;

  //Drawer ========
  const [open, setOpen] = useState(false);

  const toggleSlider = (category) => {
    setOpen(!open);
    changeCategory(currentCategory)
  };

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 1 }}>
        <Grid item xs={12} align="center">
          <Link to={"/homeuser"} state={{ category: "Gallos" }}>
            <img src={LogoCompleto} alt={"logoGALLOMX"} style={{ marginTop: "1em", width: "160px" }}
              onClick={() => toggleSlider(userViews[0])} />
          </Link>
        </Grid>
      </Grid>
      <Divider />
      <List>
        {userViews.map((listItem, index) => (
          <Link to={"/" + listItem.text} key={index} state={{ category: listItem?.category }}>
            <ListItem className={classes.listItem} button key={index}>
              <ListItemIcon className={classes.listItem} onClick={() => toggleSlider(listItem)}>
                {listItem.listIcon}
              </ListItemIcon>
              <ListItemText className={classes.listText} primary={listItem.listText} onClick={() => toggleSlider(listItem)} />
            </ListItem>
          </Link>
        ))}
        <Stack
          className={classes.listItem}
          sx={{ pt: 2, ml: 2.5 }}
          direction="row"
          spacing={3}
          justifyContent="flex-start"
        >
          <LogoutIcon />
          <Button variant="text" className={classes.listText} size='small' onClick={handleLogout}>
            cerrar sesión
          </Button>
        </Stack>
      </List>
    </Box>
  );

  const handleLogout = async () => {
    await logOut();
    toggleSlider();
    setNavbarIsActive(false);
    setNavbarUser(false);
    setBalance(0);
    navigate("/");
  };

  const getViewsList = async () => {
    if (!user.uid) {
      setNavbarIsActive(false);
      return setNavbarUser(false);
    } else {
      await getUserData(user).then((snapshot) => {
        if (snapshot === null || snapshot.data().userName === null) {
          window.location.reload(false);
          return;
        }
        setNavbarIsActive(true);
        setNavbarUser(true);
        setBalance(snapshot.data().balance)
        if (snapshot.data().type === "banned") {
          setUserViews(viewsUserType.active);
        }
        if (snapshot.data().type === "") {
          setUserViews(viewsUserType.active);
        } else {
          setUserViews(viewsUserType[snapshot.data().type]);
        }
      });

      const unsub = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
        let fixedBalance = doc.data().balance;
        setBalance(fixedBalance.toFixed(2));
      });

      return () => unsub();
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getViewsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!showNav) {
    return null;
  }

  return (
    <>
      {navbarIsActive ?
        <Box>
          <>
            <AppBar position="fixed" color="inherit" style={{ backgroundColor: "rgb(14, 14, 14)" }}>
              <Toolbar style={{ paddingLeft: "0px", paddingRight: "5px", width: "100%" }}>
                <div className='nav-bar-image-container'>
                  <img src={LogoMenu} alt={"logoGALLOMX"} style={{ height: "24px", width: "24px", marginRight: "1em", cursor: "pointer" }}
                    onClick={(e) => toggleSlider(currentCategory)} />
                  <Link to='/homeuser' state={{ category: 'Fútbol' }}><img className='image-size' src={LogoPequeño} alt={"logoGALLOMX"} /></Link>
                </div>
                <div className='nav-bar-container'>
                  <div style={{ display: "flex", alignItems: "center", width: "33%", justifyContent: "center" }}>
                    <Link to='/homeuser' state={{ category: 'Fútbol' }}>
                      <Typography variant="h1" component="div" color="inherit" style={{ fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff", }}
                        onClick={() => changeCategory(1)}>
                        {showNavbarUser ? <>PELEAS</> : <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>GalloWin</Link>}
                      </Typography>
                    </Link>
                    <Link to='/sports-user' state={{ category: 'Fútbol' }}>
                      <Typography variant="h1" component="div" color="inherit" style={{ fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory !== 1 ? "rgb(56, 161, 105)" : "#fff", }}
                        onClick={() => changeCategory(2)}>
                        {showNavbarUser ? <>DEPORTES</> : <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>GalloWin</Link>}
                      </Typography>
                    </Link>
                    {/* <Link to='/homeuser' state={{ category: 'Fútbol' }}> */}
                    <Typography variant="h1" component="div" color="inherit" style={{ fontSize: "12px", letterSpacing: "2px", color: currentCategory === 0 ? "rgb(56, 161, 105)" : "#fff", }}
                    // onClick={() => changeCategory(2)}
                    >
                      {showNavbarUser ? <>RULETA</> : <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>GalloWin</Link>}
                    </Typography>
                    {/* </Link> */}
                  </div>
                </div>
                <div className='nav-bar-balance-container'>
                  <Drawer open={open} anchor="left" onClose={toggleSlider}>
                    {sideList()}
                  </Drawer>
                  {showNavbarUser ? <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                      <Link to={"/perfil"}>
                        <div>
                          <Typography variant="body1" color="white"
                            style={{
                              padding: "6px 10px",
                              alignItems: "center",
                              display: "flex",

                            }}>
                            <PersonIcon className='icon-size' />
                          </Typography>
                        </div>
                      </Link>
                      <Link to={"/lastbets"} className='icon-display'>
                        <div>
                          <Typography variant="body1" color="white"
                            style={{
                              padding: "6px 13px 6px 0px",
                              alignItems: "center",
                              display: "flex",

                            }}>
                            <img src={iconWallet} alt={"wallet"} className='icon-size' />
                          </Typography>
                        </div>
                      </Link>
                      <Link to={"/deposits"}>
                        <Typography variant="body1" color="white"
                          style={{
                            minHeight: "30px",
                            borderRadius: 6,
                            alignItems: "center",
                            display: "flex",
                            backgroundColor: "#38A169",
                            padding: "4px 16px",
                            fontSize: "13px",
                            marginRight: "5px",
                            width: "90%",
                            justifyContent: "center",
                          }}>
                          ${balance}
                        </Typography>
                      </Link>
                    </div>
                  </> : <></>
                  }
                </div>
              </Toolbar>
            </AppBar>
            <div className={classes.offset}></div>
          </>
        </Box>
        : null
      }
    </>
  );
}