import { create } from 'zustand';

export const useUsersStore = create((set, get) => ({
    users: [],
    pagination: {
        page: 1,
        pageSize: 10,
        pageStarts: [null]
    },
    currentPage: 1,
    listSize: null,
    searchByName: '',
    searchByEmail: '',
    setState: (key, value) => set((state) => ({ [key]: value })),
}));