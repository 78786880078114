import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@material-ui/core";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { collection, getDocs, query, where, addDoc, onSnapshot, doc, updateDoc, increment } from "firebase/firestore";
import { styled } from '@mui/material/styles';
import style from "styled-components";
import { makeStyles } from '@material-ui/core';
import { Progress } from 'antd';


const FormControlLabelStyled = styled(FormControlLabel)({
  '& .MuiCheckbox-root': {
    color: "#fff9"
  },
  '& .MuiFormControlLabel-label': {
    color: "#fff9"
  },
});

const TableRowStyled = styled(TableRow)({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #1F1F1F',
  },
});

const TableHeadStyled = styled(TableHead)({
  '& .MuiTableRow-root': {
    background: "#1A1A1A",
  },
});

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    borderRadius: "10px"
  },
  '& label.Mui-focused': {
    color: '#ffffffcf',
    borderRadius: "10px"

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(56, 161, 105)',
    background: '#1a1a1a',
    borderRadius: "10px"
  },

  '& .MuiInputLabel-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffffcf',
      background: "#1e1e1e",
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px",
        background: "#1a1a1a"
      },
    }
  },
});

const CardRetiros = style.div`
background: #141414;
    padding: 5px 3px 18px 3px;
    margin-top: 1em;
    border-radius: 10px;
`;

const MarginTableTop = style.div`
    background: #141414;
    padding: 1em;
    margin-top: 1em;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 50px;
`;

const MarginTableBottom = style.div`
    background: #141414;
    padding: 1em;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    height: 35px;
`;

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "22px",
    lineHeight: "22.8px",
    textAlign: "center",
    color: "#fff"
  },
});
const RetirarFondos = () => {
  //Data variables
  const classes = useStyles();
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [bank, setBank] = useState("");
  const [moneyAmount, setMoneyAmount] = useState(0);
  const [withdrawalDate, setWithdrawalDate] = useState(new Date());
  const [status, setStatus] = useState("");
  const [uid, setUid] = useState("");
  const [userName, setUserName] = useState("");


  const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
  const { user, userInfo } = useUserAuth();

  const [success, setSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [moneyUser, setMoneyUser] = useState(0);

  const [myWithdrawals, setMyWithdrawals] = useState([]);
  const [amountPercent, setAmountPercent] = useState([]);
  const [percentWithDrawals, setPercentWithDrawals] = useState([]);

  const [retention, setRetention] = useState(0);

  const getMyWithdrawals = async () => {
    const q = query(withdrawalsCollectionRef, where("uid", "==", user.uid));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    setMyWithdrawals(items);
  };

  const getUserData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
      setMoneyUser(doc.data().balance);
      setUserName(doc.data().userName);
    });
    return () => unsubscribe();
  }

  const navigate = useNavigate();
  const redirect = () => {
    setTimeout(function () {
      navigate("/homeuser");
    }, 5000);
  }

  useEffect(() => {
    if (user?.uid) {
      getUserData();
      getMyWithdrawals();
      validateWithDraw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const validateWithDraw = async (e) => {
    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    const depositData = []
    const betsData = []
    let totalAmount = 0
    let bets = 0
    querySnapshot.forEach((doc) => {
      if (doc.data().status === "approved") {
        depositData.push({ ...doc.data(), id: doc.id });
      }
    });

    const betsCollectionRef = collection(firestore, "Bets");
    const queryBets = query(betsCollectionRef, where("uidUser", "==", user.uid));
    const querySnapshotBet = await getDocs(queryBets);
    querySnapshotBet.forEach((doc) => {
      if (doc.data().status !== "rechazada" && doc.data().status !== "por aprobar") {
        betsData.push({ ...doc.data(), id: doc.id });
      }
    });

    const betsEventsCollectionRef = collection(firestore, "EventsBets");
    const queryBetsEvents = query(betsEventsCollectionRef, where("uidUser", "==", user.uid));
    const querySnapshotBetEvents = await getDocs(queryBetsEvents);
    querySnapshotBetEvents.forEach((doc) => {
      if (doc.data().status !== "rechazada" && doc.data().status !== "por aprobar") {
        betsData.push({ ...doc.data(), id: doc.id });
      }
    });

    for (let index = 0; index < depositData.length; index++) {
      totalAmount += depositData[index].amount;
    }
    for (let index = 0; index < betsData.length; index++) {
      bets += betsData[index].bet;
    }

    setAmountPercent(bets - totalAmount)
    setPercentWithDrawals(bets/totalAmount*100)

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (amountPercent < 0) {
      setAlertMessage("Aún no puedes retirar, debes apostar para poder retirar");
      return;
    }
    if (!user.uid) {
      setAlertMessage("Usuerio no encontrado");
      return;
    }

    if (moneyAmount <= 0) {
      setAlertMessage("Cantidad invalida");
      setSuccessMessage("");
      setSuccess(false);
      return;
    }

    if (name === "" || cardNumber === "" || bank === "") {
      setAlertMessage("Campos vacios!");
      setSuccessMessage("");
      setSuccess(false);
      return;
    }

    if (moneyUser < moneyAmount) {
      setAlertMessage("No dispones de esa cantidad");
      setSuccessMessage("");
      setSuccess(false);
      return;
    }
    try {
      setUid(user.uid);
      setWithdrawalDate(new Date());
      setStatus("waiting")
      setSuccess(true);
      setAlertMessage("");
      setSuccessMessage("Tu retiro ha sido enviado a revisión!");
    } catch (error) {
      console.error(error);
      setAlertMessage("Error inesperado");
      setSuccess(false);
    }
  };

  const handleChange = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }
    const amount = parseFloat(event.target.value);
    setMoneyAmount(amount);
    if (!amount) {
      setRetention(0);
      return;
    }
    const ret = (amount * 0.01).toFixed(2);
    setRetention(Number(ret));
  };

  const validatePaste = (event) => {
    event.preventDefault();
  }

  async function CreateData() {
    //Create db structure
    const newWithdrawal = {
      name,
      cardNumber,
      bank,
      email: userInfo?.email,
      moneyAmount,
      approvedDate: "",
      aui: "",
      authorizedUserName: "",
      status,
      uid,
      voucher: "",
      withdrawalDate,
      userName,
      retention,
    }
    await addDoc(withdrawalsCollectionRef, newWithdrawal)
  }

  const balanceAfterWithdrawal = async () => {
    const userDoc = doc(firestore, "Users", user.uid);
    await updateDoc(userDoc, {
      balance: increment(-moneyAmount)
    });
  };

  // request a weekday along with a long date
  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    if (success) {
      CreateData();
      balanceAfterWithdrawal();
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <>
      <div style={{ backgroundColor: "rgb(14, 14, 14)" }}>
        <Container component="main" maxWidth="sm" style={{ backgroundColor: "rgb(14, 14, 14)" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CardRetiros>
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs sx={{ mt: 2, }} >
                  <Typography className={classes.typography} style={{ fontSize: "20px" }}>
                    Retirar fondos
                  </Typography>
                  <br></br>
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography className={classes.typography} style={{ fontSize: "17px" }}>
                  Ingresa los datos de tu cuenta bancaria para realizar la solicitud de retiro.
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Box
                  style={{ backgroundColor: "#1A1A1A" }}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: 'center',
                    flexDirection: 'column',
                    p: 3,
                    m: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%), 0px 1px 5px 0px rgb(0 0 0 / 0%)"
                  }}>
                  <CssTextField
                    margin="normal"
                    required
                    fullWidth
                    id="formName"
                    label="Nombre completo"
                    name="name"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <CssTextField
                    margin="normal"
                    required
                    fullWidth
                    id="formCardNumber"
                    label="No. de tarjeta o CLABE"
                    name="cardNumber"
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                  <CssTextField
                    margin="normal"
                    required
                    fullWidth
                    id="formBank"
                    label="Nombre del banco"
                    name="bank"
                    type="text"
                    onChange={(e) => setBank(e.target.value)}
                  />
                  <FormControlLabelStyled control={<Checkbox />} label="Guardar datos" />
                  <CssTextField
                    margin="normal"
                    required
                    fullWidth
                    id="formMoneyAmount"
                    label="Cantidad a retirar"
                    name="moneyAmount"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={moneyAmount}
                    onChange={handleChange}
                    onPaste={validatePaste}
                  />
                  <div>
                    <Typography className={classes.typography} style={{ fontSize: "14px" }}>
                      De acuerdo con la ley de juegos y sorteos se te retendrá el 1% de cada retiro. 
                      Estarás recibiendo en tu cuenta {moneyAmount ? moneyAmount - retention : 0} pesos
                    </Typography>
                  </div>
                </Box>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                  {alertMessage !== "" &&
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Alert severity="error">{alertMessage}</Alert>
                    </Grid>}
                  {successMessage !== "" &&
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Alert severity="success">{successMessage}</Alert>
                    </Grid>}

                  {amountPercent >= 0 && <Button
                    style={{
                      borderRadius: 10,
                      backgroundColor: "#781113",
                      padding: "6px 43px",
                      fontSize: "14px"
                    }}
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 1.5, mb: 2 }}
                  >
                    Enviar solicitud
                  </Button>}
                </Box>
              </div>
              {amountPercent >= 0 &&
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography className={classes.typography} style={{ fontSize: "17px" }}>
                    Tu retiro estará listo a más tardar 1 día hábil o si existe algún inconveniente te lo notificaremos.
                  </Typography>
                </div>}
              {amountPercent < 0 &&
              <div>
                <div style={{ display: "flex", justifyContent: "center", padding:"1em" }}>
                  <Typography className={classes.typography} style={{ fontSize: "19px" }}>
                  En éste momento no puedes retirar fondos, te sugerimos consultar los términos y condiciones de nuestro servicio.
                  </Typography>
                </div>
                <Typography className={classes.typography} style={{ fontSize: "19px" }}>
                  Sigue apostando hasta llenar la barra para poder retirar.
                  </Typography>
                <div style={{padding:"1em 8em 1em 8em"}}><Progress percent={percentWithDrawals.toFixed(2)} size="small" /></div>
                </div>}
            </CardRetiros>
            <Divider sx={{ pt: 3 }} />
            <MarginTableTop />
            <TableContainer component={Paper} style={{ backgroundColor: "#4a4949", color: "ffffff" }}>
              <Table style={{ backgroundColor: "rgb(20, 20, 20)" }} sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHeadStyled>
                  <TableRowStyled >
                    <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px" }}>Fecha</Typography></TableCell>
                    <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px" }}>Estatus</Typography></TableCell>
                    <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px" }}>Cantidad</Typography></TableCell>
                  </TableRowStyled>
                </TableHeadStyled>
                <TableBody>
                  {myWithdrawals.map((doc, index) => (
                    <TableRowStyled
                      key={doc.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >

                      <TableCell><Typography className={classes.typography} style={{ fontSize: "15px" }}>{new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}</Typography></TableCell>

                      {doc.status === "waiting" &&
                        <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px", color: '#603B9A' }}>En espera</Typography></TableCell>}
                      {doc.status === "declined" &&
                        <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px", color: '#B3261E' }}>Declinado</Typography></TableCell>}
                      {doc.status === "approved" &&
                        <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px", color: '#50AF48' }}>Aprobado</Typography></TableCell>}

                      {doc.status === "declined" &&
                        <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px" }}>+ ${doc.moneyAmount}</Typography></TableCell>}
                      {doc.status !== "declined" &&
                        <TableCell align="center"><Typography className={classes.typography} style={{ fontSize: "15px" }}>- ${doc.moneyAmount}</Typography></TableCell>}
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <MarginTableBottom />
            <Divider sx={{ pt: 5, pb: 5 }} />
          </Box>
        </Container>
      </div>
    </>
  );

}

export default RetirarFondos;
