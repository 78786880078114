import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail,
  // getAuth,
  // getIdToken
} from "firebase/auth"
import { getDoc, doc, setDoc, collection, onSnapshot, query } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { useLocation } from 'react-router-dom';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [fightId, setFightId] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [notifications, setNotifications] = useState(null);

  const [showNav, setShowNav] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [stateModal, setStateModal] = useState(null);
  const [modalBalance, setModalBalance] = useState(false);

  const location = useLocation();

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function forgotPass(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  function setUpRecaptcha(number) {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        { 'size': 'invisible' },
        auth
      );
      window.recaptchaVerifier.render();
    }
    else {
      window.recaptchaVerifier.render();
    }
    return signInWithPhoneNumber(auth, number, window.recaptchaVerifier);
  }

  const createUserData = async (user, newUser) => {
    if (!user.uid) return;
    //crear referencia al documento
    const userRef = doc(firestore, `Users/${user.uid}`);

    //buscar documento
    const consulta = await getDoc(userRef);

    //Revisar si existe
    if (consulta.exists()) {
      const infoDocu = consulta.data();
      return console.log("Usuario existente", infoDocu);
    } else {
      await setDoc(userRef, newUser);
    }
  }

  const getNotificationsData = async () => {
    setLoading(true);
    try {
      const collectionRef = collection(firestore, "Notifications");
      const q = query(collectionRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const notificationsItems = [];
        const notificationsActive = [];
        querySnapshot.forEach((doc) => {
          notificationsItems.push({ ...doc.data(), id: doc.id });
          if(doc.data().public === true){
            notificationsActive.push({...doc.data(), id: doc.id})
          }
        });
        setNotifications(notificationsItems);
        setAlert(notificationsActive[0])
      });
      return () => unsubscribe();
    } catch (error) {
      console.error("Error al obtener las notificaciones:", error);
    }
  };

  function loadSpin(isLoading) {
    setLoadingComponent(isLoading)
  }

  async function getUserData(auth) {
    const docuRef = doc(firestore, `Users/${auth.uid}`)
    const docuCifrada = await getDoc(docuRef);
    return docuCifrada;
  };

  function getFightIdHandler(id) {
    setFightId(id);
  };

  async function getRol(uid) {
    const docRef = doc(firestore, "Users", uid);
    const docSnap = await getDoc(docRef);
    let userData = { ...docSnap.data() }
    if (userData.type === "") {
      userData.type = "active"
    }
    return userData;
  };

  function setUserWithFirebaseAndRol(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then((userData) => {
      setUserInfo(userData);
      setUser(usuarioFirebase);
      setLoading(false);
    });
  }

  const changeCategory = (id) => {
    setActiveCategory(id);
  }
  useEffect(() => {
    getNotificationsData()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setAlert(false)
      if (currentUser) {
        setLoading(true);
        if (!user) {
          setUserWithFirebaseAndRol(currentUser);
        }
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsMobile(true);
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const fightViewRegex = /^\/fight-view\/[^/]+$/;
    const eventViewRegex = /^\/event-view\/[^/]+$/;
    if (isMobile && (fightViewRegex.test(location.pathname) || eventViewRegex.test(location.pathname))) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  }, [location, isMobile]);

  return (
    <userAuthContext.Provider
      value={{
        user, signUp, logIn, logOut, setUpRecaptcha, createUserData, getUserData, loadSpin, modalBalance, setModalBalance, 
        fightId, getFightIdHandler, loading, loadingComponent, userInfo, changeCategory, activeCategory,
        forgotPass, isMobile, showNav, setShowNav, alert, setAlert, infoMessage, setInfoMessage, openModal, setOpenModal, stateModal, setStateModal, notifications
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

//================================================