import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { collection, getDocs, query, where, orderBy, limit, getCountFromServer, startAfter } from "firebase/firestore";
import { styled } from '@mui/material/styles';
import style from "styled-components";
import { makeStyles } from '@material-ui/core';
import Loading from "./Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
});

const StyledTab = styled(Tab)({
  minWidth: '70px',
  color: '#ffffff',
  fontSize: '15px',
  fontWeight: 'normal',
  '@media (max-width: 600px)': {
    fontSize: '11px',
  },
});

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    borderRadius: "10px"
  },
  '& label.Mui-focused': {
    color: '#ffffffcf',
    borderRadius: "10px"

  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgb(56, 161, 105)',
    background: '#1a1a1a',
    borderRadius: "10px"
  },

  '& .MuiInputLabel-root': {
    color: '#ffffffcf',
    borderColor: 'rgb(56, 161, 105)',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffffcf',
      background: "#1e1e1e",
      borderColor: 'rgb(56, 161, 105)',
      borderRadius: "10px"
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgb(56, 161, 105)',
        borderRadius: "10px",
        background: "#1a1a1a"
      },
    }
  },
});

const ContainerPerfil = style.div`
background: rgb(20, 20, 20);
    width: 100%;
    border-radius: 10px;
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 2em;

     @media (max-width: 1000px) {
   padding: 0em;
  }
`;

const ContainerSteps = style.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4em;
  padding-right: 4em;
  flex-direction: column;

  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "22px",
    fontWeight: "700",
    lineHeight: "16.8px",
    textAlign: "left"
  },
});

const Perfil = () => {
  const { loadSpin, loadingComponent, user, userInfo } = useUserAuth();
  const [tabValue, setTabValue] = useState(0);
  const [rouletteBets, setRouletteBets] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [eventBets, setEventBets] = useState([]);
  const classes = useStyles();

  const [fightBets, setFightBets] = useState([]);
  const [paginationFights, setPaginationFights] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [fightCurrentPage, setFightCurrentPage] = useState(1);
  const [fightListSize, setFightListSize] = useState(null);

  const handlePageChangeFights = (newPage) => {
    setFightCurrentPage(newPage);
    setPaginationFights(prev => ({ ...prev, page: newPage }));
    getFightBets(newPage);
  };

  const getFightBets = async (page) => {
    const { pageSize, pageStarts } = paginationFights;
    const startAfterPage = pageStarts[page - 1];
  
    let queryPastFights = query(
      collection(firestore, "Bets"),
      orderBy("date", "desc"),
      where("uidUser", "==", user.uid),
      limit(pageSize)
    );
  
    if (startAfterPage) {
      queryPastFights = query(queryPastFights, startAfter(startAfterPage));
    }

    try {
      const snapshot = await getDocs(queryPastFights);
      const docs = snapshot.docs;
      const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
      setFightBets(newItems);
    
      if (page === pageStarts.length && docs.length === pageSize) {
        pageStarts.push(docs[docs.length - 1]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loadSpin(false);
    }
  };

  const getFightBetsCount = async () => {
    let queryPastFights = query(
      collection(firestore, "Bets"),
      where("uidUser", "==", user.uid),
    );

    const snapshotCount = await getCountFromServer(queryPastFights);
    setFightListSize(snapshotCount.data().count);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dateOptions = {
    month: "long",
    day: "numeric",
    year: 'numeric'
  };

  const [paginationRoulette, setPaginationRoulette] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [rouletteCurrentPage, setRouletteCurrentPage] = useState(1);
  const [rouletteListSize, setRouletteListSize] = useState(null);

  const getRouletteBets = async (page) => {
    const { pageSize, pageStarts } = paginationRoulette;
    const startAfterPage = pageStarts[page - 1];
  
    let queryRouletteBets = query(
      collection(firestore, "RouletteBets"),
      orderBy("date", "desc"),
      where("uidUser", "==", user.uid),
      limit(pageSize)
    );
  
    if (startAfterPage) {
      queryRouletteBets = query(queryRouletteBets, startAfter(startAfterPage));
    }

    try {
      const snapshot = await getDocs(queryRouletteBets);
      const docs = snapshot.docs;
      const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
      setRouletteBets(newItems);
    
      if (page === pageStarts.length && docs.length === pageSize) {
        pageStarts.push(docs[docs.length - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRouletteBetsCount = async () => {
    let queryRouletteBets = query(
      collection(firestore, "RouletteBets"),
      where("uidUser", "==", user.uid),
    );

    const snapshotCount = await getCountFromServer(queryRouletteBets);
    setRouletteListSize(snapshotCount.data().count);
  };

  const handlePageChangeRoulette = (newPage) => {
    setRouletteCurrentPage(newPage);
    setPaginationRoulette(prev => ({ ...prev, page: newPage }));
    getRouletteBets(newPage);
  };

  const [paginationDeposits, setPaginationDeposits] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [depositsCurrentPage, setDepositsCurrentPage] = useState(1);
  const [depositsListSize, setDepositsListSize] = useState(null);

  const handlePageChangeDeposits = (newPage) => {
    setDepositsCurrentPage(newPage);
    setPaginationDeposits(prev => ({ ...prev, page: newPage }));
    getDeposits(newPage);
  };

  const getDeposits = async (page) => {
    const { pageSize, pageStarts } = paginationDeposits;
    const startAfterPage = pageStarts[page - 1];

    let queryDeposits = query(
      collection(firestore, "Deposits"),
      orderBy("depositDate", "desc"),
      where("uid", "==", user.uid),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryDeposits = query(queryDeposits, startAfter(startAfterPage));
    }

    try {
      const snapshot = await getDocs(queryDeposits);
      const docs = snapshot.docs;
      const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));

      setDeposits(newItems);

      if (page === pageStarts.length && docs.length === pageSize) {
        pageStarts.push(docs[docs.length - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getDepositsCount = async () => {
    let queryDeposits = query(
      collection(firestore, "Deposits"),
      where("uid", "==", user.uid),
    );

    const snapshotCount = await getCountFromServer(queryDeposits);
    setDepositsListSize(snapshotCount.data().count);
  };

  const [withdrawalsPagination, setWithdrawalsPagination] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [withdrawalsCurrentPage, setWithdrawalsCurrentPage] = useState(1);
  const [withdrawalsListSize, setWithdrawalsListSize] = useState(null);

  const handlePageChangeWithdrawals = (newPage) => {
    setWithdrawalsCurrentPage(newPage);
    setWithdrawalsPagination(prev => ({ ...prev, page: newPage }));
    getWithdrawals(newPage);
  };

  const getWithdrawals = async (page) => {
    const { pageSize, pageStarts } = withdrawalsPagination;
    const startAfterPage = pageStarts[page - 1];

    let queryWithdrawals = query(
      collection(firestore, "Withdrawals"),
      orderBy("withdrawalDate", "desc"),
      where("uid", "==", user.uid),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryWithdrawals = query(queryWithdrawals, startAfter(startAfterPage));
    }

    try {
      const snapshot = await getDocs(queryWithdrawals);
      const docs = snapshot.docs;
      const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));

      setWithdrawals(newItems);

      if (page === pageStarts.length && docs.length === pageSize) {
        pageStarts.push(docs[docs.length - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getWithdrawalsCount = async () => {
    let queryWithdrawals = query(
      collection(firestore, "Withdrawals"),
      where("uid", "==", user.uid),
    );

    const snapshotCount = await getCountFromServer(queryWithdrawals);
    setWithdrawalsListSize(snapshotCount.data().count);
  };

  const [eventPagination, setEventPagination] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [eventCurrentPage, setEventCurrentPage] = useState(1);
  const [eventListSize, setEventListSize] = useState(null);

  const handlePageChangeEvent = (newPage) => {
    setEventCurrentPage(newPage);
    setEventPagination(prev => ({ ...prev, page: newPage }));
    getEventBets(newPage);
  };

  const getEventBets = async (page) => {
    const { pageSize, pageStarts } = eventPagination;
    const startAfterPage = pageStarts[page - 1];

    let queryEventBets = query(
      collection(firestore, "EventsBets"),
      orderBy("date", "desc"),
      where("uidUser", "==", user.uid),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryEventBets = query(queryEventBets, startAfter(startAfterPage));
    }

    try {
      const snapshot = await getDocs(queryEventBets);
      const docs = snapshot.docs;
      const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));

      setEventBets(newItems);

      if (page === pageStarts.length && docs.length === pageSize) {
        pageStarts.push(docs[docs.length - 1]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEventBetsCount = async () => {
    let queryEventBets = query(
      collection(firestore, "EventsBets"),
      where("uidUser", "==", user.uid),
    );

    const snapshotCount = await getCountFromServer(queryEventBets);
    setEventListSize(snapshotCount.data().count);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    var stringArray = name.split(/(\s+)/);
    if (stringArray.length === 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    }
    if (stringArray.length >= 2) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
  }

  useEffect(() => {
    getFightBets(1);
    getFightBetsCount();
    getRouletteBets(1);
    getRouletteBetsCount();
    getDeposits(1);
    getDepositsCount();
    getWithdrawals(1);
    getWithdrawalsCount();
    getEventBets(1);
    getEventBetsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loadingComponent &&
        <Loading />
      }
      {!loadingComponent &&
        <div style={{ backgroundColor: "rgb(14, 14, 14)" }}>
          <Container maxWidth="md" style={{ backgroundColor: "rgb(14, 14, 14)" }}>
            <Container maxWidth="sm">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <ContainerPerfil>
                  <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ width: "90%" }}>
                    <Grid item xs={3} sx={{ mt: 2, mr: 1 }} >
                      <Avatar style={{ width: 54, height: 54, backgroundColor: "#781113" }} {...stringAvatar(userInfo.userName)} />
                    </Grid>
                    <Grid item xs={8} sx={{ mt: 2, }} >
                      <Typography sx={{ mb: 1, }} className={classes.typography} color={'#ffffff'} style={{ fontSize: "17px", fontWeight: "normal" }} >
                        {userInfo.userName.split(" ")[0]}
                      </Typography>
                      <Typography className={classes.typography} color={'#ffffff'} style={{ fontSize: "13px", fontWeight: "normal" }}>
                        Perfil de usuario
                      </Typography>
                    </Grid>
                  </Grid>
                    <Box sx={{
                      width: "90%",
                      justifyContent: 'center',
                      flexDirection: 'column',
                      p: 3,
                      m: 2,
                      borderRadius: 2,
                    }}>
                      <CssTextField
                        margin="normal"
                        required
                        id="Nombre"
                        label="Nombre"
                        fullWidth
                        value={userInfo.userName}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ mb: 2 }}
                      />
                      <CssTextField
                        id="Telefono"
                        label="Teléfono:"
                        fullWidth
                        value={userInfo.phoneNumber}
                        sx={{ mb: 2 }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <CssTextField
                        id="Email"
                        label="Email:"
                        fullWidth
                        value={userInfo.email}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ mb: 2 }}
                      />
                      <CssTextField
                        id="Direccion"
                        label="Dirección:"
                        fullWidth
                        value={userInfo.address}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                </ContainerPerfil>              
              </Box>
            </Container>        
            <ContainerSteps>
              <div>
                <StyledTabs
                  value={tabValue}
                  onChange={handleChange}
                  indicatorColor=""
                  textColor="inherit"
                  sx={{ width: "100%" }}
                >
                  <StyledTab label="Peleas" />
                  <StyledTab label="Deportes" />
                  <StyledTab label="Ruletas" />
                  <StyledTab label="Depositos" />
                  <StyledTab label="Retiros" />
                </StyledTabs>                
              </div>
                {tabValue === 0 &&
                  <>
                    <TableContainer sx={{ mt: 5, mb: 5 }}>
                      <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ color: "white" }}>Pelea</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Color</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Apuesta</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fightBets.map((doc, index) => (
                            <TableRow
                              key={doc.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.fightName}</TableCell>
                              {doc.color === "Rojo" &&
                                <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#D32F2F' }}>
                                  {doc.color}
                                </TableCell>
                              }
                              {doc.color === "Verde" &&
                                <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#2E7D32' }}>
                                  {doc.color}
                                </TableCell>
                              }
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.bet}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>
                                {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                      <Button
                        disabled={fightCurrentPage === 1}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeFights(fightCurrentPage - 1)}>
                        <LeftOutlined style={{ color: "white" }} />
                      </Button>
                      <p style={{color: 'white'}}>{fightCurrentPage} de {Math.ceil(fightListSize / paginationFights.pageSize)}</p>
                      <Button
                        disabled={fightCurrentPage === Math.ceil(fightListSize / paginationFights.pageSize)}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeFights(fightCurrentPage + 1)}
                      >
                        <RightOutlined style={{ color: "white" }} />
                      </Button>
                    </div>
                  </>
                }
                {tabValue === 1 && 
                  <>
                    <TableContainer sx={{ mt: 5, mb: 5 }}>
                      <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ color: "white" }}>Evento</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Equipo</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Apuesta</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eventBets.map((doc, index) => (
                            <TableRow
                              key={doc.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.eventName}</TableCell>
                              <TableCell align="center" component="th" scope="row" style={{ fontSize: "14px", color: '#fff' }}>
                                {doc.teamName}
                              </TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.bet}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>
                                {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                      <Button
                        disabled={eventCurrentPage === 1}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeEvent(eventCurrentPage - 1)}>
                        <LeftOutlined style={{ color: "white" }} />
                      </Button>
                      <p style={{color: 'white'}}>{eventCurrentPage} de {Math.ceil(eventListSize / eventPagination.pageSize)}</p>
                      <Button
                        disabled={eventCurrentPage === Math.ceil(eventListSize / eventPagination.pageSize)}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeEvent(eventCurrentPage + 1)}
                      >
                        <RightOutlined style={{ color: "white" }} />
                      </Button>
                    </div>
                  </>
                }
                {tabValue === 2 &&
                  <>
                    <TableContainer sx={{ mt: 5, mb: 5 }}>
                      <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ color: "white" }}>Precio</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Número</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rouletteBets.map((doc, index) => (
                            <TableRow
                              key={doc.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.amount}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.selectedNumber}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>
                                {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                      <Button
                        disabled={rouletteCurrentPage === 1}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeRoulette(rouletteCurrentPage - 1)}>
                        <LeftOutlined style={{ color: "white" }} />
                      </Button>
                      <p style={{color: 'white'}}>{rouletteCurrentPage} de {Math.ceil(rouletteListSize / paginationRoulette.pageSize)}</p>
                      <Button
                        disabled={rouletteCurrentPage === Math.ceil(rouletteListSize / paginationRoulette.pageSize)}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeRoulette(rouletteCurrentPage + 1)}
                      >
                        <RightOutlined style={{ color: "white" }} />
                      </Button>
                    </div>
                  </>  
                }
                {tabValue === 3 &&
                  <>
                    <TableContainer sx={{ mt: 5, mb: 5 }}>
                      <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ color: "white" }}>Cantidad</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {deposits.map((doc, index) => (
                            <TableRow
                              key={doc.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.amount}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>
                                {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                      <Button
                        disabled={depositsCurrentPage === 1}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeDeposits(depositsCurrentPage - 1)}>
                        <LeftOutlined style={{ color: "white" }} />
                      </Button>
                      <p style={{color: 'white'}}>{depositsCurrentPage} de {Math.ceil(depositsListSize / paginationDeposits.pageSize)}</p>
                      <Button
                        disabled={depositsCurrentPage === Math.ceil(depositsListSize / paginationDeposits.pageSize)}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeDeposits(depositsCurrentPage + 1)}
                      >
                        <RightOutlined style={{ color: "white" }} />
                      </Button>
                    </div>
                  </>
                }
                {tabValue === 4 &&
                  <>
                    <TableContainer sx={{ mt: 5, mb: 5 }}>
                      <Table sx={{ minWidth: 650, backgroundColor: "#1A1A1A" }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ color: "white" }}>Cantidad</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Estatus</TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>Fecha</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {withdrawals.map((doc, index) => (
                            <TableRow
                              key={doc.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.moneyAmount}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>{doc.status}</TableCell>
                              <TableCell align="center" sx={{ color: '#fff' }}>
                                {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
                      <Button
                        disabled={withdrawalsCurrentPage === 1}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeWithdrawals(withdrawalsCurrentPage - 1)}>
                        <LeftOutlined style={{ color: "white" }} />
                      </Button>
                      <p style={{color: 'white'}}>{withdrawalsCurrentPage} de {Math.ceil(withdrawalsListSize / withdrawalsPagination.pageSize)}</p>
                      <Button
                        disabled={withdrawalsCurrentPage === Math.ceil(withdrawalsListSize / withdrawalsPagination.pageSize)}
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => handlePageChangeWithdrawals(withdrawalsCurrentPage + 1)}
                      >
                        <RightOutlined style={{ color: "white" }} />
                      </Button>
                    </div>
                  </>
                }
            </ContainerSteps>
          </Container>          
        </div>
      }
    </>
  );
};

export default Perfil;
