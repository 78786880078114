import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore, storage } from "../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { addDays, format } from 'date-fns'
import { updateDoc, doc, collection, query, getDocs, where, limit, orderBy } from "firebase/firestore";
import { styled } from '@mui/material/styles';
import { Spin, Select, Upload, message, notification } from "antd";
// import iconCard from "../Images/icon-tarjeta.png"
import iconSpei from "../Images/icon-spei.png"
import iconAutoServ from "../Images/icon-autoserv.png"
import Grid from '@mui/material/Grid';
import { paymentAmounts } from "../utils/constants";
import DepositsDataService from "../Services/deposits.services"
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import { Button as BtnComp } from '../components/ui';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import './AddMoney.css';
import style from "styled-components";

const CssTextField = styled(TextField)({
  width: "100%",
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
    background: '#1a1a1a'
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
    fontFamily: "Outfit",
    fontSize: "18px",
    lineHeight: "16.8px",
    textAlign: "center",
    '&.Mui-disabled': {
      color: '#888888',
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(56, 161, 105)',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#888888',
    },
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
    background: "#1a1a1a",
    borderColor: '#ffffff',
    '&.Mui-disabled': {
      color: '#888888',
      background: '#1a1a1a',
      '-webkit-text-fill-color': '#ffffff',
    },
  },
});

const styleSuccess = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  minHeight: 250,
  maxHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "22px",
    fontWeight: "700",
    lineHeight: "16.8px",
    textAlign: "center"
  },
});

const ContainerButton = style.div`
border-radius: 10px;
    font-size: 12px;
    color: rgb(56, 161, 105);
    background-color: rgb(29, 29, 27);
    width: 20%;
    margin-right: 5px;
    display: flex;
    justify-content: center;
`;


const AddMoney = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [reference, setReference] = useState(null);
  const { user, userInfo } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [amount, setDeposito] = useState(0);
  const [paymentOption, setPaymentOption] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [payFormat, setPayFormat] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [showDefaultPayment, setShowDefaultPayment] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [id, setId] = useState(null);
  const { Option } = Select;
  const [file, setFile] = useState({});
  const classes = useStyles();

  useEffect(() => {
    handleChangePaymentMethod("express");
    if(userInfo?.referenceClubPago){
      setReference(userInfo.referenceClubPago)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const generateIdClubPago = async () => {
    const random = Math.floor(Math.random() * 1000000);
    const newId = random.toString().padStart(6, '0')

    const usersCollectionRef = collection(firestore, "Users");
    const q = query(usersCollectionRef, where("idClubPago", "==", newId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      return generateIdClubPago();
    }

    const userDoc = doc(firestore, "Users", user.uid);
    await updateDoc(userDoc, {
      idClubPago: newId,
    });

    return newId;
  }

  const handleBetStandarAmount = async (amount) => {
    setDeposito(amount);
  }

  const handleClickCopySPEI = (value) => {
    setOpenCopy(true);
    navigator.clipboard.writeText(value);
  };

  const DocuPDF = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "38px" }}>Ficha digital</Text>
          </View>
          <View style={{ padding: 30, backgroundColor: "#E3E3E3", marginTop: "20px" }}>
            <Text style={{ fontSize: "18px" }}>
              Instrucciones
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "10px" }}>
              1. Realiza tu pago con cualquiera de los establecimientos permitidos.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              2. Toma una foto de tu comprobante de pago.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            <Text style={{ color: "gray", fontWeight: "extrabold", fontSize: "20px" }}>MONTO A PAGAR</Text>
            <Text style={{ marginTop: "20px", fontWeight: "bold", fontSize: "32px" }}>
              $ {amount} MXN
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Transferencia electrónica {"(SPEI)"}:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              CLABE: 058320000000893020
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Banco: Banregio
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Deposito en efectivo:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              Número de tarjeta: 4741 7406 0220 7885
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Beneficiario: GalloWin
            </Text>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "20px" }}>
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Oxxo_Logo.svg/2560px-Oxxo_Logo.svg.png"
                alt="OXXO"
                style={{ maxWidth: "80px", maxHeight: "40px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                alt="7Eleven"
                style={{ maxWidth: "40px", maxHeight: "40px", marginLeft: "30px" }}
              />
              <Image
                src="https://pbs.twimg.com/profile_images/1082319678893023234/QM2qBfnH_400x400.jpg"
                alt="Soriana"
                style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "25px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/en/b/be/Tiendas_Extra_logo.png"
                alt="Extra"
                style={{ maxWidth: "100px", maxHeight: "100px", paddingBottom: "50px", marginLeft: "25px" }}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  const handleUpdate = async () => {
    setLoading(true);

    const metadata = {
      contentType: "image/jpeg",
    };
    const storageRef = ref(storage, `comprobantes/${id}`);

    try {
      await uploadBytes(storageRef, file, metadata);
      const urlFile = await getDownloadURL(storageRef);
      const depositDoc = doc(firestore, "Deposits", id);
      await updateDoc(depositDoc, {
        voucher: urlFile,
      });

      setFile({});
      notification.success({
        message: "Tu comprobante fue enviado con éxito",
        description: "Revisaremos tu comprobante, Tu nuevo saldo se verá reflejado en tu cuenta pronto.",
        placement: "bottomRight"
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setShowError(true);
      setErrorMessage("Error al procesar tu solicitud, intenta de nuevo mas tarde.");
    }
  };

  const submitPaymentTraditional = async () => {
    try {
      const newDeposit = {
        amount,
        approvedDate: "",
        aui: "",
        authorizedUserName: "",
        depositDate: new Date(),
        payMethod: "default",
        status: "waiting",
        uid: user.uid,
        userName: userInfo.userName,
        voucher: "",
      }
      const response = await DepositsDataService.addDeposit(newDeposit);
      setId(response.id);
      setSuccess(true);
      setShowDefaultPayment(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setShowError(true);
      setErrorMessage("Error al procesar tu solicitud, intenta de nuevo mas tarde.");
      setLoading(false);
    }
  };

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      padding: 2,
      margin: 2,
      marginTop: "40px",
      flexGrow: 1
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonPressed(true);
    setLoading(true);
    const depositoParse = parseFloat(amount);
    setDeposito(depositoParse);
    if (!amount || amount <= 0) {
      setLoading(false);
      setButtonPressed(false);
      return;
    }

    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let hasPending = false;
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "waiting") {
          hasPending = true;
        }
      });
      if (hasPending) {
        setShowError(true);
        setErrorMessage("Tienes un deposito pendiente, espera a que sea aprobado o puedes cancelarlo en caso de que no hayas hecho el deposito.");
        setLoading(false);
        return;
      }
    }

    if (paymentMethod === "tradicional") {
      return submitPaymentTraditional();
    }

    const auth = getAuth();
    const { currentUser } = auth
    const token = await getIdToken(currentUser);
    let idClubPago = null;
    if (userInfo?.idClubPago === null || userInfo?.idClubPago === undefined) {
      idClubPago = await generateIdClubPago();
    } else {
      idClubPago = userInfo.idClubPago;
    }

    try {
      let transaccion = 1;
      const depositsCollectionRef = collection(firestore, "Deposits");
      const q = query(depositsCollectionRef, orderBy("depositDate", "desc"), limit(1));
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        const hasProperty = doc.data().hasOwnProperty('transaccion');
        if (hasProperty) {
          transaccion = Number(doc.data().transaccion) + 1;
        }
      }
      const expirationDate = addDays(new Date(), 2);
      const formatedDate = format(expirationDate, 'yyyy-MM-dd');
      const currentAmount = amount.toString() + '00';
      const bodyReference = {
        Description: "Deposito",
        Amount: Number(currentAmount),
        Account: idClubPago,
        CustomerEmail: userInfo.email,
        CustomerName: userInfo.userName,
        ExpirationDate: formatedDate,
        RequestClabe: 1,
        RequestPayTD: paymentOption === "tarjeta" ? 1 : 0,
      };
      const responseReference = await fetch(`${process.env.REACT_APP_API_URL}/generate-reference`, {
        method: "POST",
        body: JSON.stringify(bodyReference),
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        }
      });
      const dataReference = await responseReference.json();
      if (responseReference.status !== 200 || dataReference.Message !== "Exitosa") {
        setShowError(true);
        setErrorMessage("Error al procesar tu solicitud, intenta de nuevo mas tarde.");
        setLoading(false);
        return;
      }

      const newDeposit = {
        amount,
        approvedDate: "",
        aui: "",
        authorizedUserName: "",
        depositDate: new Date(),
        payMethod: paymentOption,
        status: "waiting",
        uid: user.uid,
        userName: userInfo.userName,
        voucher: "",
        referenceClubPago: dataReference.Reference,
        folioClubPago: dataReference.Folio,
        clabeClubPago: dataReference.Clabe,
        expirationDate,
        transaccion: transaccion.toString(),
        payFormat: dataReference?.PayFormat,
      }
      await DepositsDataService.addDeposit(newDeposit);
      const hasPropertyIdClubPago = userInfo.hasOwnProperty('idClubPago');
      const hasPropertyClabeClubPago = userInfo.hasOwnProperty('clabeClubPago');
      const hasPropertyReferenceClubPago = userInfo.hasOwnProperty('referenceClubPago');
      if (!hasPropertyIdClubPago || !hasPropertyClabeClubPago || !hasPropertyReferenceClubPago) {
        const userDoc = doc(firestore, "Users", user.uid);
        await updateDoc(userDoc, {
          referenceClubPago: dataReference.Reference,
          clabeClubPago: dataReference.Clabe,
        });
      }
      setReference(dataReference.Reference)
      setSuccess(true);
      setLoading(false);
      if (paymentOption === "tarjeta") {
        window.open(dataReference.PaymentTD, '_blank');
      } else {
        setPayFormat(dataReference.PayFormat);
        setOpenModal(true);
      }
    } catch (error) {
      console.error(error);
      setShowError(true);
      setErrorMessage("Error al procesar tu solicitud, intenta de nuevo mas tarde.");
      setLoading(false);
    }
  };

  const handleChangePayment = async (value) => {
    setPaymentOption(value);
  };

  const handleChange = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }
    const deposito = parseInt(event.target.value);
    setDeposito(deposito);
    if ((paymentOption === "spei" || paymentOption === "tienda") && deposito < paymentAmounts.spei.minumun) {
      setShowError(true);
      setErrorMessage("El monto minimo es de $100");
      return;
    }
    if (paymentOption === "tarjeta" && deposito < paymentAmounts.tarjeta.minumun) {
      setShowError(true);
      setErrorMessage("El monto minimo es de $500");
      return;
    }
    setShowError(false);
    setErrorMessage("");
  };

  const validatePaste = (event) => {
    event.preventDefault();
  }

  const handleCloseSuccess = () => {
    setOpenModal(false);
    setReference(userInfo.referenceClubPago)
    navigate("/deposits");
  };

  const handleChangePaymentMethod = (value) => {
    setPaymentMethod(value);
    if (value === "express") {
      setDeposito(500);
      setPaymentOption(null);
    } else {
      setPaymentOption("tradicional");
      setDeposito(100);
    }
  };

  const handleBeforeUpload = (file) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 3;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 3MB!", 3);
      return Upload.LIST_IGNORE;
    }
    setFile(file)
    return false;
  };

  return (
    <div className="container-general-add-money">
      {!showDefaultPayment && (
        <div className="container-add-money">
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className="card-container-add-money">
              <Grid container sx={{ width: "100%" }}>
                <Grid item xs sx={{ mt: 2, }} >
                  <Typography className={classes.typography} component="h1" variant="h5" color={'#ffffff'} fontSize="22px" align="center" >
                    Agregar fondos
                  </Typography>
                  <br></br>
                </Grid>
              </Grid>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginBottom: "2em" }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }} className="container-form">
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <BtnComp
                      variant={paymentMethod === "express" ? "fill" : "outlined"}
                      onClick={() => handleChangePaymentMethod("express")}
                    >
                      DEPÓSITO EXPRESS (15 Min)
                    </BtnComp>
                    <BtnComp
                      variant={paymentMethod === "tradicional" ? "fill" : "outlined"}
                      onClick={() => handleChangePaymentMethod("tradicional")}
                    >
                      DEPÓSITO TRADICIONAL (Hasta 24 horas)
                    </BtnComp>
                  </div>

                  {paymentMethod === "express" && (
                    <Select
                      style={{ width: "100%", marginTop: "1rem" }}
                      onChange={handleChangePayment}
                      placeholder="Selecciona un método de pago"
                    >
                      <Option value="spei"><img style={{ width: "1em", height: "1em", fontSize: "1.5rem", marginRight: "5px", marginBottom: "1px" }} src={iconSpei} alt='icon-menu'></img>Transferencia SPEI</Option>
                      <Option value="tienda"><img style={{ width: "1em", height: "1em", fontSize: "1.5rem", marginRight: "5px", marginBottom: "1px" }} src={iconAutoServ} alt='icon-menu'></img>Pago en tienda de auto servicio</Option>
                      {/* <Option value="tarjeta"><img style={{ width: "1em", height: "1em", fontSize: "1.5rem", marginRight: "5px", marginBottom: "1px" }} src={iconCard} alt='icon-menu'></img>Tarjeta de crédito/débito</Option> */}
                    </Select>

                  )}

                  {paymentMethod === "express" && (
                    <>                    
                      {(paymentMethod === "express") && (reference) &&

                        <div className="reference-container">
                          <Typography sx={{ fontSize: 16, color: "white", textAlign: "center" }}>
                            Referencia de pago:
                          </Typography>
                          <div>{reference}</div>
                        </div>

                      }
                      {paymentOption && (
                        <>
                          <div style={{ width: "100%", marginTop: "5px" }}>
                            <div className="container-payment-amounts">
                              {paymentAmounts[paymentOption].options.map((amount, index) => {
                                return (
                                  <ContainerButton>
                                    <Button
                                      style={{ color: "rgb(56, 161, 105)" }}
                                      onClick={(e) => handleBetStandarAmount(amount)}>
                                      ${amount}
                                    </Button>
                                  </ContainerButton>
                                )
                              })}
                            </div>
                          </div>
                          <CssTextField
                            margin="normal"
                            required
                            fullWidth
                            id="formDeposito"
                            label="Cantidad a depositar"
                            type="number"
                            name="deposito"
                            InputProps={{ inputProps: { min: 0 } }}
                            value={amount}
                            disabled
                          />
                        </>
                      )}
                    </>
                  )}


                  {(paymentMethod === "tradicional") && (
                    <>
                      <div style={{ width: "100%", marginTop: "5px" }}>
                        <div className="container-payment-amounts">
                          {paymentAmounts[paymentOption].options.map((amount, index) => {
                            return (
                              <ContainerButton>
                                <Button
                                  style={{ color: "rgb(56, 161, 105)" }}
                                  onClick={(e) => handleBetStandarAmount(amount)}>
                                  ${amount}
                                </Button>
                              </ContainerButton>
                            )
                          })}
                        </div>
                      </div>
                      <CssTextField
                        margin="normal"
                        required
                        fullWidth
                        id="formDeposito"
                        label="Cantidad a depositar"
                        type="number"
                        name="deposito"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={amount}
                        onChange={handleChange}
                        onPaste={validatePaste}
                      />
                    </>
                  )}

                  {success &&
                    <Alert sx={{ mt: 1, mb: 1 }} severity="success">
                      Tu petición ha sido enviada con exito!
                    </Alert>
                  }
                  {(success && (paymentOption === "spei" || paymentOption === "tienda")) &&
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                      <a href={payFormat} target="_blank" rel="noreferrer" style={{ width: "100%" }}>
                        <Button variant="contained" color="inherit"
                          onClick={(e) => handleCloseSuccess()}
                          style={{
                            borderRadius: 35,
                            padding: "12px 50px",
                            fontSize: "14px",
                            backgroundColor: "#EA9F26",
                            color: "white",
                            width: "100%"
                          }}>
                          Ver Ficha de Pago
                        </Button>
                      </a>
                    </div>
                  }
                  {showError &&
                    <Alert sx={{ mt: 1, mb: 1 }} severity="error">
                      {errorMessage}
                    </Alert>
                  }
                  {(!buttonPressed && !showError && paymentOption && amount >= paymentAmounts[paymentOption].minumun) &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <BtnComp
                        type="submit"
                      >
                        DEPOSITAR
                      </BtnComp>
                    </div>
                  }
                </Box>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDefaultPayment && (
        <Container component="main" maxWidth="xs" style={{ backgroundColor: "#141414", paddingTop: "10px", borderRadius: "10px" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ mt: 2, mb: 2 }}>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: "15px" }}>
                <Typography sx={{ fontSize: 14 }} gutterBottom style={{ paddingBottom: "5px", textAlign: "center", color: "#fff" }}>
                  Cantidad: ${amount} MXN
                </Typography>
              </div>
              <Card sx={{ minWidth: 300 }} style={{ backgroundColor: '#141414' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                    Transferencia electrónica {"(SPEI)"}:
                  </Typography>
                  <Grid container >
                    <Grid item xs={10}>
                      <Typography variant="body2" color="#ffffff">
                        CLABE: 058320000000893020
                        <br />
                        Banco: Banregio
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("058320000000893020")}>
                        <ContentCopyIcon />
                      </IconButton>
                      <Snackbar
                        message="SPEI copiado"
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        autoHideDuration={1500}
                        onClose={() => setOpenCopy(false)}
                        open={openCopy}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: 300, mt: 2 }} style={{ backgroundColor: '#141414' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                    Deposito en efectivo:
                  </Typography>
                  <Grid container >
                    <Grid item xs={10}>
                      <Typography variant="body2" color="#ffffff">
                        Número de tarjeta: 4741 7406 0220 7885
                        <br />
                        Beneficiario: GalloWin
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("4741 7406 0220 7885")}>
                        <ContentCopyIcon />
                      </IconButton>
                      <Snackbar
                        message="SPEI copiado"
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        autoHideDuration={1500}
                        onClose={() => setOpenCopy(false)}
                        open={openCopy}
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }} xs={3}>
                      <img src="https://w7.pngwing.com/pngs/785/454/png-transparent-oxxo-hd-logo.png"
                        alt="oxxo" style={{ marginLeft: "10px", width: "50%", height: "auto" }}
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }} xs={3}>
                      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                        alt="7eleven" style={{ marginLeft: "10px", width: "35%", height: "auto" }}
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }} xs={3}>
                      <img src="https://w7.pngwing.com/pngs/890/945/png-transparent-soriana-hd-logo.png"
                        alt="soriana" style={{ width: "80%", height: "auto", }}
                      />
                    </Grid>
                    <Grid item sx={{ mt: 2 }} xs={3}>
                      <img src="https://seeklogo.com/images/E/extra-logo-63D58EACBA-seeklogo.com.png"
                        alt="extra" style={{ marginLeft: "10px", width: "60%", height: "auto" }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: "10px", paddingTop: "10px" }}>
                <PDFDownloadLink document={<DocuPDF />} fileName="ficha.pdf">
                  <button className="btn-download-file">
                    Descargar ficha digital  <DownloadOutlined />
                  </button>
                </PDFDownloadLink>
              </div>
              <Card sx={{ minWidth: 300 }} style={{ backgroundColor: '#141414' }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                    Intrucciones
                  </Typography>
                  <Grid container >
                    <Grid item xs={12}>
                      <Typography variant="body2" color="#ffffff" sx={{ fontSize: 12 }}>
                        1. Realiza tu pago con cualquiera de los establecimientos permitidos.
                        <br />
                        2. Toma una foto de tu comprobante de pago.
                        <br />
                        3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
                        <br />
                        4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
                        <br />
                        5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Alert style={{ backgroundColor: '#141414', color: '#fff' }} sx={{ mt: 3 }} severity="info">Envíanos tu comprobante de pago para actualizar tu saldo.</Alert>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', paddingTop: "60px", paddingBottom: "40px" }}>
                <Upload
                  beforeUpload={(file) => handleBeforeUpload(file)}
                  onRemove={() => setFile({})}
                  maxCount={1}
                  listType="picture"
                >
                  <button className="btn-download-file">
                    Seleccionar comprobante <UploadOutlined />
                  </button>
                </Upload>

                {file?.name && (
                  <div style={{ paddingTop: "50px" }}>
                    <BtnComp
                      onClick={handleUpdate}
                    >
                      Enviar comprobante
                    </BtnComp>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Container>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modaFl-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSuccess}>
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                ¡Tu petición ha sido enviada con exito!
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
              <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
              <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                Cantidad: ${amount}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
              <a href={payFormat} target="_blank" rel="noreferrer">
                <Button variant="contained" color="inherit" size="large"
                  onClick={(e) => handleCloseSuccess()}
                  style={{
                    borderRadius: 35,
                    padding: "12px 50px",
                    fontSize: "14px",
                    backgroundColor: "#EA9F26",
                    color: "white"
                  }}>
                  Ver Ficha de Pago
                </Button>
              </a>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {loading && (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1100,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            width: '100%',
            height: '600vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin
            style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            size="large"
            spinning={loading}
          />
        </div>
      )}
    </div>
  )
}

export default AddMoney;
