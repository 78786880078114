import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useUserAuth } from "../context/UserAuthContext";
import { collection, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { Modal as ModalAnt, Carousel } from "antd";
import Modal from '@mui/material/Modal';
import { CloseOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import './HomeUser.css';
import PanelBet from "./PanelBet";
import SportTable from "./SportTable";
import { makeStyles } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  background: "#141414"
};

const useStyles = makeStyles({
  typography: {
    fontFamily: "Outfit",
    fontSize: "16px",
    lineHeight: "20.8px",
    textAlign: "center",
    color: "#fff",
    margin: "0px 0px 17px 0px"
  }
})

const SportsUser = () => {
  const { userInfo, user, getUserData } = useUserAuth();
  const [ads, setAds] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [urlMedia, setUrlMedia] = useState("");
  const { activeCategory, changeCategory } = useUserAuth();
  const currentCategory = activeCategory === null ? 1 : activeCategory;
  const [openModal, setOpenModal] = useState(false);
  const classes = useStyles();
  const handleClose = () => setOpenModal(false);

  let { state } = useLocation();

  const handleOpenModalAdd = (url) => {
    setUrlMedia(url)
    setOpenModalAdd(true);
  }

  const handleCloseModalAdd = () => {
    setUrlMedia("");
    setOpenModalAdd(false);
  }

  async function updateDate(date, activeLive) {
    const userDoc = doc(firestore, "Users", user.uid);
    try {
      await updateDoc(userDoc, {
        dateBalance: date,
        activeLive: activeLive
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getUser = async () => {
    await getUserData(user).then((snapshot) => {

      if (userInfo.type === "active") {
        if (snapshot.data().balance < 100) {
          if (!snapshot.data().dateBalance) {
            let currentDate = new Date()
            updateDate(currentDate, true)
          } else {
            const currentDate = new Date()
            const date = snapshot.data().dateBalance.toDate()
            const dif = currentDate.getTime() - date.getTime()
            const days = dif / (1000 * 60 * 60 * 24)
            if (days >= 1) {
              updateDate(snapshot.data().dateBalance, false)
            }
          }
          setOpenModal(true)
        } else {
          updateDate(null, true)
        }
      }
    });
  };

  const getADS = async () => {
    const queryAdss = query(
      collection(firestore, "Ads"), where("isActive", "==", true)
    );
    const querySnapshot = await getDocs(queryAdss);
    setAds(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    if (user?.uid) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getADS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Modal Ads */}
      <Modal
        open={openModalAdd}
        onClose={handleCloseModalAdd}
      >
        <Box sx={style}>
          <div>
            <CloseOutlined onClick={handleCloseModalAdd} style={{ position: "absolute", right: "30px", top: "20px", fontSize: "20px", cursor: "pointer", color: "#fff" }} />
          </div>
          <img src={urlMedia} alt="ad" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </Box>
      </Modal>
      <div style={{ backgroundColor: "rgb(14, 14, 14)" }}>
        {/* ADS */}
        {userInfo?.type !== "banned" &&
          <div>
            <div className="nav-bar-container-vertical">
              <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <Link to='/homeuser'>
                  <Typography variant="h1" component="div" color="inherit" style={{ marginTop: "1em", fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory === 1 ? "rgb(56, 161, 105)" : "#fff", }}
                    onClick={() => changeCategory(1)}>
                    <>PELEAS</>
                  </Typography>
                </Link>
                <Link to='/sports-user' state={{ category: 'Fútbol' }}>
                  <Typography variant="h1" component="div" style={{ marginTop: "1em", fontSize: "12px", marginRight: "4em", letterSpacing: "2px", color: currentCategory !== 1 ? "rgb(56, 161, 105)" : "#fff", }}
                    onClick={() => changeCategory(2)}>
                    <>DEPORTES</>
                  </Typography>
                </Link>
                {/* <Link to='/homeuser'> */}
                  <Typography variant="h1" component="div" color="white" style={{ marginTop: "1em", fontSize: "12px", letterSpacing: "2px" }} >
                    <>RULETA</>
                  </Typography>
                {/* </Link> */}
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="container-carousel-panel-bet">
                <div className="carousel-container">
                  <Carousel autoplay>
                    {ads.map((ad) => (
                      <div key={ad.id} style={{ padding: "2em" }}>
                        <div onClick={() => handleOpenModalAdd(ad.urlMediaComplete)} style={{ padding: "2em" }}>
                          <img
                            className="carousel-img"
                            src={ad.urlMedia}
                            alt={ad.name}
                            style={{ objectFit: "cover", width: "100%", borderRadius: "13px" }}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>

                <SportTable category={state.category} />
              </div>
              <div className="container-panel-bet">
                <PanelBet category={currentCategory} />
              </div>
            </div>
          </div>}

        {userInfo?.type === "banned" &&
          <Box
            sx={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 10
            }}
          >
            <Typography component="h1" variant="h5" color={'error'} style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }}>
              Tu cuenta ha sido desactivada. Para más información ponte en contacto con algun miembro de la administración de GalloWin
            </Typography>
          </Box>
        }
        <div>
          <ModalAnt
            open={openModal}
            onClose={handleClose}
            closable={false}
            footer={null}
          >
            <p className={classes.typography} style={{ fontSize: "17px" }}>¡ATENCIÓN!</p>
            <p className={classes.typography} style={{ paddingTop: "5px", textAlign: "left", fontWeight: "normal" }}>
              Para seguir disfrutando de nuestro streaming, debes tener al menos $100.00 en tu cuenta. Por ahora, seguirás teniendo acceso por 24 horas, después el video en vivo será bloqueado hasta que agregues saldo a tu cuenta.
            </p>
            <div className='container-buttons-footer-modal'>
              <div className='item'>
                <button
                  key="submit"
                  className='btnSelectPaymentModal'
                  onClick={handleClose}
                >
                  Continuar
                </button>
              </div>
            </div>
          </ModalAnt>
        </div>
      </div>
    </>
  );
};

export default SportsUser;
